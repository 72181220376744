@font-face {
  font-family: 'Playfair Display Subset';
  font-style: normal;
  font-weight: 700;
  src: url('./Playfair-Display-Bold-subset.woff2') format('woff2'),
    url('./Playfair-Display-Bold-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Source-Sans-Pro-subset.woff2') format('woff2'), url('./Source-Sans-Pro-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro Subset';
  font-style: normal;
  font-weight: 700;
  src: url('./Source-Sans-Pro-Bold-subset.woff2') format('woff2'),
    url('./Source-Sans-Pro-Bold-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Quattrocento Subset';
  font-style: normal;
  font-weight: 700;
  src: url('./Quattrocento-Bold-subset.woff2') format('woff2'), url('./Quattrocento-Bold-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Fanwood Text Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Fanwood-Text-subset.woff2') format('woff2'), url('./Fanwood-Text-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant Subset';
  font-style: normal;
  font-weight: 700;
  src: url('./Cormorant-Bold-subset.woff2') format('woff2'), url('./Cormorant-Bold-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Proza Libre Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Proza-Libre-subset.woff2') format('woff2'), url('./Proza-Libre-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Oswald Subset';
  font-style: normal;
  font-weight: 700;
  src: url('./Oswald-Bold-subset.woff2') format('woff2'), url('./Oswald-Bold-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./EB-Garamond-subset.woff2') format('woff2'), url('./EB-Garamond-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Libre Baskerville Subset';
  font-style: normal;
  font-weight: 700;
  src: url('./Libre-Baskerville-Bold-subset.woff2') format('woff2'),
    url('./Libre-Baskerville-Bold-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Cinzel Subset';
  font-style: normal;
  font-weight: 700;
  src: url('./Cinzel-Bold-subset.woff2') format('woff2'), url('./Cinzel-Bold-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Fauna One Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Fauna-One-subset.woff2') format('woff2'), url('./Fauna-One-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Sacramento Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Sacramento-subset.woff2') format('woff2'), url('./Sacramento-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Alice Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Alice-Regular-subset.woff2') format('woff2'), url('./Alice-Regular-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Yeseva One Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Yeseva-One-subset.woff2') format('woff2'), url('./Yeseva-One-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Josefin Sans Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Josefin-Sans-subset.woff2') format('woff2'), url('./Josefin-Sans-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat Subset';
  font-style: normal;
  font-weight: 300;
  src: url('./Montserrat-subset.woff2') format('woff2'), url('./Montserrat-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat Subset';
  font-style: normal;
  font-weight: 700;
  src: url('./Montserrat-Bold-subset.woff2') format('woff2'), url('./Montserrat-Bold-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Cardo Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Cardo-Regular-subset.woff2') format('woff2'), url('./Cardo-Regular-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Cardo Subset';
  font-style: normal;
  font-weight: 700;
  src: url('./Cardo-Bold-subset.woff2') format('woff2'), url('./Cardo-Bold-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Spectral Subset';
  font-style: normal;
  font-weight: 700;
  src: url('./Spectral-Bold-subset.woff2') format('woff2'), url('./Spectral-Bold-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Karla Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Karla-subset.woff2') format('woff2'), url('./Karla-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Halant Subset';
  font-style: normal;
  font-weight: 700;
  src: url('./Halant-Bold-subset.woff2') format('woff2'), url('./Halant-Bold-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Nunito-Sans-subset.woff2') format('woff2'), url('./Nunito-Sans-subset.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Hind Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Hind-subset.woff2') format('woff2'), url('./Hind-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Subset';
  font-style: normal;
  font-weight: 700;
  src: url('./Nunito-Bold-subset.woff2') format('woff2'), url('./Nunito-Bold-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./PT-Sans-subset.woff2') format('woff2'), url('./PT-Sans-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Merriweather-subset.woff2') format('woff2'), url('./Merriweather-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Crimson-Pro-subset.woff2') format('woff2'), url('./Crimson-Pro-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif Pro Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Source-Serif-Pro-subset.woff2') format('woff2'), url('./Source-Serif-Pro-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Raleway Subset';
  font-style: normal;
  font-weight: 700;
  src: url('./Raleway-Bold-subset.woff2') format('woff2'), url('./Raleway-Bold-subset.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Subset';
  font-style: normal;
  font-weight: 400;
  src: url('./Open-Sans-subset.woff2') format('woff2'), url('./Open-Sans-subset.woff') format('woff');
  font-display: swap;
}
