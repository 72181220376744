@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Inter.woff2') format('woff2'), url('~@shared/fonts/Inter.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('~@shared/fonts/Inter-Semi.woff2') format('woff2'), url('~@shared/fonts/Inter-Semi.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Playfair-Display-Bold.woff2') format('woff2'),
    url('~@shared/fonts/Playfair-Display-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Playfair-Display-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Playfair-Display-Bold-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Source-Sans-Pro.woff2') format('woff2'),
    url('~@shared/fonts/Source-Sans-Pro.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('~@shared/fonts/Source-Sans-Pro-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Source-Sans-Pro-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Source-Sans-Pro-Bold.woff2') format('woff2'),
    url('~@shared/fonts/Source-Sans-Pro-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Source-Sans-Pro-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Source-Sans-Pro-Bold-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Quattrocento';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Quattrocento-Bold.woff2') format('woff2'),
    url('~@shared/fonts/Quattrocento-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Fanwood Text';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Fanwood-Text.woff2') format('woff2'), url('~@shared/fonts/Fanwood-Text.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Fanwood Text';
  font-style: italic;
  font-weight: 400;
  src: url('~@shared/fonts/Fanwood-Text-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Fanwood-Text-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Cormorant-Bold.woff2') format('woff2'),
    url('~@shared/fonts/Cormorant-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Cormorant-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Cormorant-Bold-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Proza Libre';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Proza-Libre.woff2') format('woff2'), url('~@shared/fonts/Proza-Libre.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Proza Libre';
  font-style: italic;
  font-weight: 400;
  src: url('~@shared/fonts/Proza-Libre-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Proza-Libre-Italic.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Proza Libre';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Proza-Libre-Bold.woff2') format('woff2'),
    url('~@shared/fonts/Proza-Libre-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Proza Libre';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Proza-Libre-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Proza-Libre-Bold-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Oswald-Bold.woff2') format('woff2'), url('~@shared/fonts/Oswald-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/EB-Garamond.woff2') format('woff2'), url('~@shared/fonts/EB-Garamond.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  src: url('~@shared/fonts/EB-Garamond-Italic.woff2') format('woff2'),
    url('~@shared/fonts/EB-Garamond-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/EB-Garamond-Bold.woff2') format('woff2'),
    url('~@shared/fonts/EB-Garamond-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/EB-Garamond-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/EB-Garamond-Bold-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Libre-Baskerville-Bold.woff2') format('woff2'),
    url('~@shared/fonts/Libre-Baskerville-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Libre Baskerville';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Libre-Baskerville-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Libre-Baskerville-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Cinzel-Bold.woff2') format('woff2'), url('~@shared/fonts/Cinzel-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Fauna One';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Fauna-One.woff2') format('woff2'), url('~@shared/fonts/Fauna-One.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Sacramento';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Sacramento.woff2') format('woff2'), url('~@shared/fonts/Sacramento.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Alice';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Alice-Regular.woff2') format('woff2'),
    url('~@shared/fonts/Alice-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Yeseva One';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Yeseva-One.woff2') format('woff2'), url('~@shared/fonts/Yeseva-One.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Josefin-Sans.woff2') format('woff2'), url('~@shared/fonts/Josefin-Sans.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 400;
  src: url('~@shared/fonts/Josefin-Sans-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Josefin-Sans-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Josefin-Sans-Bold.woff2') format('woff2'),
    url('~@shared/fonts/Josefin-Sans-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Josefin-Sans-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Josefin-Sans-Bold-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('~@shared/fonts/Montserrat.woff2') format('woff2'), url('~@shared/fonts/Montserrat.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('~@shared/fonts/Montserrat-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Montserrat-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('~@shared/fonts/Montserrat-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Montserrat-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Montserrat-Bold-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Cardo';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Cardo-Regular.woff2') format('woff2'),
    url('~@shared/fonts/Cardo-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Cardo';
  font-style: italic;
  font-weight: 400;
  src: url('~@shared/fonts/Cardo-Italic.woff2') format('woff2'), url('~@shared/fonts/Cardo-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Cardo';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Cardo-Bold.woff2') format('woff2'), url('~@shared/fonts/Cardo-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Spectral-Bold.woff2') format('woff2'),
    url('~@shared/fonts/Spectral-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Spectral';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Spectral-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Spectral-Bold-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Karla.woff2') format('woff2'), url('~@shared/fonts/Karla.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 400;
  src: url('~@shared/fonts/Karla-Italic.woff2') format('woff2'), url('~@shared/fonts/Karla-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Karla-Bold.woff2') format('woff2'), url('~@shared/fonts/Karla-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Karla-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Karla-Bold-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Halant';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Halant-Bold.woff2') format('woff2'), url('~@shared/fonts/Halant-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 400;
  src: url('~@shared/fonts/Nunito-Sans-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Nunito-Sans-Italic.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Nunito-Sans.woff2') format('woff2'), url('~@shared/fonts/Nunito-Sans.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Nunito-Sans-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Nunito-Sans-Bold-Italic.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Nunito-Sans-Bold.woff2') format('woff2'),
    url('~@shared/fonts/Nunito-Sans-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Hind-Bold.woff2') format('woff2'), url('~@shared/fonts/Hind-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Hind.woff2') format('woff2'), url('~@shared/fonts/Hind.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Nunito-Bold.woff2') format('woff2'), url('~@shared/fonts/Nunito-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/PT-Sans.woff2') format('woff2'), url('~@shared/fonts/PT-Sans.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 400;
  src: url('~@shared/fonts/PT-Sans-Italic.woff2') format('woff2'),
    url('~@shared/fonts/PT-Sans-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/PT-Sans-Bold.woff2') format('woff2'), url('~@shared/fonts/PT-Sans-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/PT-Sans-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/PT-Sans-Bold-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Merriweather.woff2') format('woff2'), url('~@shared/fonts/Merriweather.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src: url('~@shared/fonts/Merriweather-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Merriweather-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Merriweather-Bold.woff2') format('woff2'),
    url('~@shared/fonts/Merriweather-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Merriweather-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Merriweather-Bold-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Crimson-Pro.woff2') format('woff2'), url('~@shared/fonts/Crimson-Pro.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  font-style: italic;
  font-weight: 400;
  src: url('~@shared/fonts/Crimson-Pro-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Crimson-Pro-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Crimson-Pro-Bold.woff2') format('woff2'),
    url('~@shared/fonts/Crimson-Pro-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Crimson-Pro-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Crimson-Pro-Bold-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Source-Serif-Pro.woff2') format('woff2'),
    url('~@shared/fonts/Source-Serif-Pro.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 400;
  src: url('~@shared/fonts/Source-Serif-Pro-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Source-Serif-Pro-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Source-Serif-Pro-Bold.woff2') format('woff2'),
    url('~@shared/fonts/Source-Serif-Pro-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Source-Serif-Pro-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Source-Serif-Pro-Bold-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Raleway-Bold.woff2') format('woff2'), url('~@shared/fonts/Raleway-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Raleway-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Raleway-Bold-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/fonts/Open-Sans.woff2') format('woff2'), url('~@shared/fonts/Open-Sans.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('~@shared/fonts/Open-Sans-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Open-Sans-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('~@shared/fonts/Open-Sans-Bold.woff2') format('woff2'),
    url('~@shared/fonts/Open-Sans-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('~@shared/fonts/Open-Sans-Bold-Italic.woff2') format('woff2'),
    url('~@shared/fonts/Open-Sans-Bold-Italic.woff') format('woff');
  font-display: swap;
}

:root {
  --base-font: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.decoupled-editor .ck-content h2,
.decoupled-editor .ck-content h3,
.decoupled-editor .ck-content h4,
.title-font {
  font-family: var(--current-title-font), var(--base-font);
}

.decoupled-editor .ck-content,
.text-font {
  font-family: var(--current-text-font), var(--base-font);
}
