@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.66;
  }
  100% {
    opacity: 0.33;
  }
}

.loading-dot {
  animation: blinking 900ms linear infinite;
}

.loading-dot--1 {
  animation-delay: 0;
}
.loading-dot--2 {
  animation-delay: 300ms;
}
.loading-dot--3 {
  animation-delay: 600ms;
}
