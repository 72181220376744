@tailwind components;
@tailwind utilities;

.ck {
  --ck-user-avatar-size: 24px;
  --ck-color-annotation-info: #8d9ba4;
  --ck-color-annotation-icon: #b4c2c8;
  --ck-font-size-small: 0.7rem;
  --ck-spacing-standard: 0.65rem;
  --ck-color-button-save: #002293;
  --ck-color-button-cancel: #002293;
  --ck-color-button-default-hover-background: transparent;
  --ck-color-split-button-hover-background: transparent;
  --ck-color-panel-border: #f2f3f5;
  --ck-color-toolbar-border: #f2f3f5;
  --ck-font-face: var(--base-font);
  --ck-color-engine-placeholder-text: #8d9ba4;
  --ck-color-comment-marker: #fff1ce;
  --ck-switch-button-toggle-spacing: 4px;
  --ck-switch-button-toggle-inner-size: 1rem;
  --ck-switch-button-toggle-width: 2.5rem;
  --ck-color-switch-button-off-background: #e5e9ed;
  --ck-color-switch-button-off-hover-background: #b4c2c8;
  --ck-color-switch-button-on-hover-background: #0043db;
  --ck-color-switch-button-on-background: #002293;
  --ck-color-switch-button-inner-shadow: transparent;
  --ck-drop-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  --ck-color-base-border: #f2f3f5;
  --ck-color-list-button-hover-background: #f8f9fa;
  --ck-focus-ring: 1px solid transparent;
  --ck-color-button-on-active-shadow: transparent;
  --ck-color-button-default-active-shadow: transparent;
  --ck-color-base-text: #111111;
  --ck-color-list-button-on-background: #f8f9fa;
  --ck-color-list-button-on-text: var(--ck-color-base-text);
  --ck-color-list-button-on-background-focus: #f8f9fa;
}

.ck-annotation__actions {
  display: flex;
  align-items: center;
}

.ck-dropdown .ck.ck-button:not(.ck-disabled):hover,
.ck-dropdown a.ck.ck-button:not(.ck-disabled):hover {
  color: #111111;
}
.ck-dropdown .ck.ck-button:not(.ck-disabled):active,
.ck-dropdown a.ck.ck-button:not(.ck-disabled):active {
  color: #111111;
  background: #f8f9fa;
}

.ck-dropdown__panel {
  border-radius: 0.25rem !important;
}

.ck.ck-button.ck-switchbutton .ck-button__toggle .ck-button__toggle__inner {
  border-radius: 1.25rem !important;
  cursor: pointer;
}
.ck.ck-button.ck-switchbutton .ck-button__toggle {
  border-radius: 1.25rem !important;
  cursor: pointer;
}
.ck.ck-button:not(.ck-disabled),
a.ck.ck-button:not(.ck-disabled) {
  @apply cursor-pointer;
}
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  @apply text-primary-600;
}

.ck.ck-button:not(.ck-disabled):active,
a.ck.ck-button:not(.ck-disabled):active {
  @apply text-primary-500;
}

.ck .ck-comment,
.ck .ck-comment__input-container,
.ck .ck-thread__comment-count {
  @apply border-l-0;
}

.ck .ck-annotation__info-name,
.ck .ck-annotation__info-time {
  @apply font-normal;
}

.ck .ck-annotation__info-time {
  @apply ml-2;
}

.ck .ck-suggestion {
  @apply border-l-0;
}

.ck .ck-comment:after {
  @apply hidden;
}

.ck-rounded-corners .ck.ck-balloon-panel,
.ck.ck-balloon-panel.ck-rounded-corners {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  @apply box-border border-r-4;
}

.ck-thread__comments::after {
  content: '';
  width: calc(100% - 32px);
  height: 1px;
  @apply block mx-4 my-0 bg-neutrals-200;
}

.ck .ck-comment__input-container {
  @apply bg-transparent text-sm;
}
.ck .ck-annotation__main p {
  @apply text-xs;
}

.decoupled-editor .ck-content p {
  margin: 0px;
  text-indent: 30px;
}

.ck-content .image {
  z-index: 0 !important;
}

.decoupled-editor .ck-content p:first-of-type {
  text-indent: 0px;
}

.poetry-book .ck-content p {
  margin: 0px;
  text-indent: 0;
}

.dissertation .ck-content p {
  margin: 0px;
  margin-bottom: 24px;
  text-indent: 0;
}

/* Set the styles for APA p and h1-h5: */
.title-case::first-letter {
  text-transform: capitalize;
}

.apa-text-indent {
  margin: 0;
  text-indent: 48px;
}

.text-period::after {
  content: '.';
}

.apa .ck-content p {
  @apply text-base apa-text-indent;
}

.apa .ck-content p:first-of-type {
  text-indent: 48px;
}

.references .ck-content p {
  padding-left: 48px !important;
  text-indent: -48px !important;
}

.apa .ck-content h2 {
  @apply text-base font-bold title-case;
  text-align: center !important;
}

.apa .ck-content h3 {
  @apply text-base font-bold text-left title-case;
}

.apa .ck-content h4 {
  @apply text-base font-bold apa-text-indent title-case text-period;
}

.apa .ck-content h5 {
  @apply text-base font-bold italic apa-text-indent title-case text-period;
}

.apa .ck-content h6 {
  @apply text-base font-normal italic apa-text-indent title-case text-period;
}

.ck .ck-thread__input {
  @apply bg-transparent;
}

.ck .ck-comment__input {
  @apply pt-0;
}

.ck .ck-thread--active .ck-thread__input {
  @apply bg-transparent;
}

.ck-comment--remove,
.ck .ck-thread--remove .ck-comment,
.ck .ck-thread__remove-confirm,
.ck-comment--edit {
  background-color: transparent !important;
}

.ck-button.ck-comment--remove svg,
.ck-button.ck-comment--edit svg {
  @apply hidden;
}

.ck-button.ck-comment--remove::before,
.ck-button.ck-comment--edit::before {
  content: '';
  @apply block w-3.75 h-3.75 bg-center bg-no-repeat;
}

.ck.ck-button.ck-comment--edit:before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.71667 5.5L10.5 6.28333L2.93333 13.8333H2.16667V13.0667L9.71667 5.5ZM12.7167 0.5C12.5083 0.5 12.2917 0.583333 12.1333 0.741667L10.6083 2.26667L13.7333 5.39167L15.2583 3.86667C15.5833 3.54167 15.5833 3 15.2583 2.69167L13.3083 0.741667C13.1417 0.575 12.9333 0.5 12.7167 0.5ZM9.71667 3.15833L0.5 12.375V15.5H3.625L12.8417 6.28333L9.71667 3.15833Z' fill='%23B4C2C8'/%3E%3C/svg%3E%0A");
}
.ck.ck-button.ck-comment--remove:before {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999837 13.8333C0.999837 14.2754 1.17543 14.6993 1.48799 15.0118C1.80055 15.3244 2.22448 15.5 2.6665 15.5H9.33317C9.7752 15.5 10.1991 15.3244 10.5117 15.0118C10.8242 14.6993 10.9998 14.2754 10.9998 13.8333V3.83333H0.999837V13.8333ZM2.6665 5.5H9.33317V13.8333H2.6665V5.5ZM8.9165 1.33333L8.08317 0.5H3.9165L3.08317 1.33333H0.166504V3H11.8332V1.33333H8.9165Z' fill='%23B4C2C8'/%3E%3C/svg%3E%0A");
}

.ck.ck-button.ck-comment--edit:not(.ck-disabled):hover:before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.71667 5.5L10.5 6.28333L2.93333 13.8333H2.16667V13.0667L9.71667 5.5ZM12.7167 0.5C12.5083 0.5 12.2917 0.583333 12.1333 0.741667L10.6083 2.26667L13.7333 5.39167L15.2583 3.86667C15.5833 3.54167 15.5833 3 15.2583 2.69167L13.3083 0.741667C13.1417 0.575 12.9333 0.5 12.7167 0.5ZM9.71667 3.15833L0.5 12.375V15.5H3.625L12.8417 6.28333L9.71667 3.15833Z' fill='%230043db'/%3E%3C/svg%3E%0A");
}
.ck.ck-button.ck-comment--remove:not(.ck-disabled):hover:before {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999837 13.8333C0.999837 14.2754 1.17543 14.6993 1.48799 15.0118C1.80055 15.3244 2.22448 15.5 2.6665 15.5H9.33317C9.7752 15.5 10.1991 15.3244 10.5117 15.0118C10.8242 14.6993 10.9998 14.2754 10.9998 13.8333V3.83333H0.999837V13.8333ZM2.6665 5.5H9.33317V13.8333H2.6665V5.5ZM8.9165 1.33333L8.08317 0.5H3.9165L3.08317 1.33333H0.166504V3H11.8332V1.33333H8.9165Z' fill='%230043db'/%3E%3C/svg%3E%0A");
}

.ck.ck-button.ck-comment--edit:not(.ck-disabled):active:before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.71667 5.5L10.5 6.28333L2.93333 13.8333H2.16667V13.0667L9.71667 5.5ZM12.7167 0.5C12.5083 0.5 12.2917 0.583333 12.1333 0.741667L10.6083 2.26667L13.7333 5.39167L15.2583 3.86667C15.5833 3.54167 15.5833 3 15.2583 2.69167L13.3083 0.741667C13.1417 0.575 12.9333 0.5 12.7167 0.5ZM9.71667 3.15833L0.5 12.375V15.5H3.625L12.8417 6.28333L9.71667 3.15833Z' fill='%230057ff'/%3E%3C/svg%3E%0A");
}
.ck.ck-button.ck-comment--remove:not(.ck-disabled):active:before {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999837 13.8333C0.999837 14.2754 1.17543 14.6993 1.48799 15.0118C1.80055 15.3244 2.22448 15.5 2.6665 15.5H9.33317C9.7752 15.5 10.1991 15.3244 10.5117 15.0118C10.8242 14.6993 10.9998 14.2754 10.9998 13.8333V3.83333H0.999837V13.8333ZM2.6665 5.5H9.33317V13.8333H2.6665V5.5ZM8.9165 1.33333L8.08317 0.5H3.9165L3.08317 1.33333H0.166504V3H11.8332V1.33333H8.9165Z' fill='%230057ff'/%3E%3C/svg%3E%0A");
}

.ck-annotation-wrapper--active .ck-button.ck-comment--edit:before,
.ck-annotation-wrapper:hover .ck-button.ck-comment--edit:before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.71667 5.5L10.5 6.28333L2.93333 13.8333H2.16667V13.0667L9.71667 5.5ZM12.7167 0.5C12.5083 0.5 12.2917 0.583333 12.1333 0.741667L10.6083 2.26667L13.7333 5.39167L15.2583 3.86667C15.5833 3.54167 15.5833 3 15.2583 2.69167L13.3083 0.741667C13.1417 0.575 12.9333 0.5 12.7167 0.5ZM9.71667 3.15833L0.5 12.375V15.5H3.625L12.8417 6.28333L9.71667 3.15833Z' fill='%23002293'/%3E%3C/svg%3E%0A");
}
.ck-annotation-wrapper--active .ck-button.ck-comment--remove:before,
.ck-annotation-wrapper:hover .ck-button.ck-comment--remove:before {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999837 13.8333C0.999837 14.2754 1.17543 14.6993 1.48799 15.0118C1.80055 15.3244 2.22448 15.5 2.6665 15.5H9.33317C9.7752 15.5 10.1991 15.3244 10.5117 15.0118C10.8242 14.6993 10.9998 14.2754 10.9998 13.8333V3.83333H0.999837V13.8333ZM2.6665 5.5H9.33317V13.8333H2.6665V5.5ZM8.9165 1.33333L8.08317 0.5H3.9165L3.08317 1.33333H0.166504V3H11.8332V1.33333H8.9165Z' fill='%23002293'/%3E%3C/svg%3E%0A");
}

.ck.ck-button.ck-comment--remove,
.ck.ck-button.ck-comment__input-actions--cancel,
.ck.ck-button.ck-suggestion--discard {
  @apply ml-2.5;
}
.ck-annotation-wrapper {
  --ck-icon-size: 15px;
}
.ck-annotation-wrapper .ck-user__img {
  @apply border-0;
}

[dir='ltr'] .ck.ck-splitbutton.ck-splitbutton_open > .ck-splitbutton__arrow:not(.ck-disabled),
[dir='ltr'] .ck.ck-splitbutton:hover > .ck-splitbutton__arrow:not(.ck-disabled) {
  @apply border-transparent;
}

.ck-presence-list .ck.ck-user {
  width: 36px;
  height: 36px;
}
