@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-base;
  }
  html,
  body,
  #root {
    @apply h-full;
    min-height: var(--app-height);
  }

  html,
  body {
    @apply overscroll-y-none;
  }

  /* Tailwind 2 does not support indeterminate state for the checkbox */
  [type='checkbox']:indeterminate {
    @apply text-primary-800 border-primary-800;
  }
  [type='checkbox']:indeterminate:hover {
    @apply text-primary-600 border-primary-600;
  }

  .ck-content {
    @apply w-full;
    @apply h-full;
    /* @apply overflow-scroll !important; */
  }

  .decoupled-editor .ck-content {
    @apply pt-6;
    @apply px-10 !important;
    @apply overflow-visible !important;
  }

  .balloon-editor .ck-content {
    @apply px-0 !important;
  }

  .ck-toolbar.ck-toolbar_grouping {
    @apply bg-neutrals-0 !important;
    @apply border-neutrals-200 !important;
    @apply shadow-none !important;
  }

  #root .ck-toolbar.ck-toolbar_grouping {
    @apply bg-neutrals-0;
    @apply border-r-0;
    @apply border-l-0;
    @apply border-t-0;
    @apply border-b;
    @apply border-neutrals-200;
    @apply h-11;
    @apply min-h-11;
  }

  .ck-toolbar svg {
    @apply text-primary-800 !important;
  }

  #root .ck-toolbar__separator {
    @apply bg-neutrals-200;
  }

  .balloon-editor .ck.ck-editor__editable_inline > :first-child {
    @apply mt-0 !important;
  }

  .document-editor {
    @apply relative;

    /* This element is a flex container for easier rendering. */
    @apply flex;
    @apply flex-col;
    @apply flex-nowrap;
  }

  .ck-toolbar {
    /* Make sure the toolbar container is always above the editable. */
    z-index: 1;
    @apply sticky !important;
    @apply top-0;
    @apply left-0;
  }

  /* Adjust the headings dropdown to host some larger heading styles. */
  .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
    line-height: calc(1.7 * var(--ck-line-height-base) * var(--ck-font-size-base));
    min-width: 6em;
  }

  /* Set the styles for "Heading 1". */
  .document-editor .ck-content h2,
  .document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
    @apply text-h1;
    @apply font-semibold;
  }

  /* Set the styles for "Heading 2". */
  .document-editor .ck-content h3,
  .document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
    @apply text-h2;
    @apply font-semibold;
  }

  /* Set the styles for "Heading 3". */
  .document-editor .ck-content h4,
  .document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
    @apply text-h3;
    @apply font-semibold;
  }

  /* Set the styles for "Heading 4". */
  .document-editor .ck-content h5,
  .document-editor .ck-heading-dropdown .ck-heading_heading4 .ck-button__label {
    @apply text-base;
    @apply font-semibold;
  }

  /* Set the styles for "Heading 5". */
  .document-editor .ck-content h6,
  .document-editor .ck-heading-dropdown .ck-heading_heading5 .ck-button__label {
    @apply text-base;
    @apply font-normal;
  }

  .document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
    color: var(--ck-color-list-button-on-text);
  }

  .document-editor .ck-content ul {
    @apply pl-6;
    @apply list-disc;
  }

  .document-editor .ck-content ol {
    @apply pl-6;
    list-style-type: auto;
  }

  /* Scale down all heading previews because they are way too big to be presented in the UI.
  Preserve the relative scale, though. */
  .document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
    transform: scale(0.8);
    transform-origin: left;
  }

  .document-editor .ck-content h2 {
    padding-top: 0.342em;
    margin-bottom: 0.142em;
  }

  /* Set the styles for "Heading 2". */
  .document-editor .ck-content h3 {
    padding-top: 0.171em;
    margin-bottom: 0.357em;
  }

  .document-editor .ck-content h4 {
    padding-top: 0.286em;
    margin-bottom: 0.952em;
  }

  /* Set the styles for "Paragraph". */
  .decoupled-editor .ck-content p {
    margin: var(--ck-spacing-large) 0;
    font-size: 1rem;
    line-height: 1.63em;
  }

  .balloon-editor .ck-content p {
    @apply text-s;
  }

  /* Make the block quoted text serif with some additional spacing. */
  .document-editor .ck-content blockquote {
    margin-left: calc(2 * var(--ck-spacing-large));
    margin-right: calc(2 * var(--ck-spacing-large));
  }

  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    @apply border border-transparent !important;
    @apply shadow-none !important;
  }
  .ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
    @apply justify-center;
  }

  #doc-editor .ck-user__marker:after {
    font-family: 'Inter';
  }

  #doc-editor {
    max-height: calc(var(--app-height) - var(--header-editor-height) - var(--toolbar-height));
    overflow: auto;
    @apply pb-4;
  }

  #book-structure {
    max-height: calc(var(--app-height) - var(--header-editor-height) - var(--left-sidebar-menu-height));
    overflow: auto;
  }

  .sidebar-right-content {
    max-height: calc(var(--app-height) - var(--header-editor-height) - var(--right-sidebar-menu-height));
    overflow: auto;
  }

  #copyright {
    max-height: calc(var(--app-height) - var(--header-editor-height) - var(--toolbar-height));
    overflow: auto;
  }

  #empty-books-centering {
    top: calc(50% - 56px);
  }

  .show-enter {
    opacity: 0;
  }
  .show-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .show-exit {
    opacity: 1;
  }
  .show-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }

  .ck-presence-list__counter {
    @apply hidden;
  }
  #root .ck.ck-presence-list {
    @apply mr-0;
    --ck-spacing-standard: 4px;
  }
  #root .ck.ck-presence-list__marker {
    @apply absolute;
  }
}

#transcription-text {
  max-height: calc(
    var(--app-height) - var(--header-editor-height) - var(--right-sidebar-menu-height-height) -
      var(--transcription-menu-height)
  );
}

button:focus:not(:focus-visible) {
  outline: none;
}

.users-online {
  display: flex;
}
.users-online__user {
  border-radius: 50%;
  padding: 2px;
  background-color: #e5e5e5;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border: 2px solid white;
  display: flex;
}
.users-online__user:not(:first-child) {
  position: relative;
  margin-left: -10px;
}

.users-online__photo {
  height: 100%;
  object-fit: contain;
}

.presence-list-wrapper {
  @apply ml-4;
}

.presence-list-wrapper:empty {
  @apply ml-0;
}

.row-presence .ck-presence-list:not(:first-child) {
  display: none;
}

.outline-button {
  @apply hover:bg-neutrals-100 active:bg-neutrals-200;
}

@media (max-width: 768px) {
  #doc-editor {
    @apply pb-0;
  }
  .decoupled-editor .ck-content,
  .balloon-editor .ck-content {
    @apply pb-14;
  }

  #book-structure {
    @apply pb-14;
  }
}

.overlay-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.w-inherit {
  width: inherit;
}
